'use client'

import { useEffect } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { usePrevState } from '@marketplace-web/shared/ui-helpers'
import { GoogleTagManagerEvent } from 'constants/google'
import { CurrencyAmountModel } from 'types/models'
import { CheckoutItemModel } from 'types/models/checkout-item'

import useGoogleTagManagerTrack from './useGoogleTagManagerTrack'

const useGoogleTrackTransactionStart = ({
  orderItems,
  orderPrice,
  transactionId,
}: {
  transactionId: number | null
  orderPrice: CurrencyAmountModel | null
  orderItems: Array<CheckoutItemModel> | null
}) => {
  const { user } = useSession()

  const prevOrderItems = usePrevState(orderItems)

  const { googleTagManagerTrack } = useGoogleTagManagerTrack()

  useEffect(() => {
    function trackTransactionStart() {
      if (!user) return

      const data = {
        user_email: user.email,
        transaction_id: transactionId,
        value: parseFloat(orderPrice?.amount || '0'),
        items: orderItems?.map(item => ({
          item_id: item.id,
          item_name: item.title,
          item_brand: item.brand,
          item_brand_id: item.brandId,
          item_category_id: item.catalogId,
          currency: item.price.currencyCode,
          item_condition: item.disposalConditions,
          value: parseFloat(item.price.amount),
        })),
      }

      googleTagManagerTrack(GoogleTagManagerEvent.BuyStart, data)
    }

    if (!orderItems) return

    const isFirstRenderAfterOrderFetch = !prevOrderItems?.length && orderItems?.length

    if (!isFirstRenderAfterOrderFetch) return

    trackTransactionStart()
  }, [user, orderItems, orderPrice, prevOrderItems, googleTagManagerTrack, transactionId])
}

export default useGoogleTrackTransactionStart
