import {
  WalletBalanceDto,
  InvoiceDto,
  WalletInvoicesDto,
  InvoiceLineDto,
  InvoiceLinesPaginationDto,
} from 'types/dtos'
import {
  InvoiceLineModel,
  InvoiceModel,
  InvoicesModel,
  WalletBalanceModel,
  InvoiceLinesPaginationModel,
} from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformWalletBalanceDto = ({
  available_amount,
  escrow_amount,
}: WalletBalanceDto): WalletBalanceModel => ({
  pendingAmount: transformCurrencyAmountDto(escrow_amount),
  availableAmount: transformCurrencyAmountDto(available_amount),
})

export const transformInvoiceDto = (invoice: InvoiceDto): InvoiceModel => ({
  invoiceSeries: invoice.invoice_series,
  invoiceNo: invoice.invoice_no,
  invoiceDate: invoice.invoice_date,
  id: invoice.id,
})

export const transformInvoiceLineDto = (line: InvoiceLineDto): InvoiceLineModel => {
  const { id, amount, date, title, subtitle, type, entity_id, entity_type } = line

  return {
    id,
    amount: transformCurrencyAmountDto(amount),
    date,
    title,
    subtitle,
    type,
    entityId: entity_id,
    entityType: entity_type,
  }
}

export const transformInvoiceLinesPaginationDto = (
  pagination: InvoiceLinesPaginationDto,
): InvoiceLinesPaginationModel => {
  const { current_page, page_size, total_pages, total_records } = pagination

  return {
    pageSize: page_size,
    totalPages: total_pages,
    currentPage: current_page,
    totalRecords: total_records,
  }
}

export const transformInvoiceLinesDto = (lines: Array<InvoiceLineDto>): Array<InvoiceLineModel> =>
  lines.map(transformInvoiceLineDto)

export const transformWalletInvoices = (walletInvoices: WalletInvoicesDto): InvoicesModel => ({
  invoiceLines: transformInvoiceLinesDto(walletInvoices.invoice_lines),
  invoiceLinesPagination:
    walletInvoices.invoice_lines_pagination &&
    transformInvoiceLinesPaginationDto(walletInvoices.invoice_lines_pagination),
  invoiceLinesHasMore:
    walletInvoices.invoice_lines_has_more && walletInvoices.invoice_lines_has_more,
  startBalance: transformCurrencyAmountDto(walletInvoices.previous_balance),
  endBalance: transformCurrencyAmountDto(walletInvoices.balance),
  startingDate: walletInvoices.starting_date,
  history: walletInvoices.history?.map(history => ({
    title: history.title,
    year: history.year,
    month: history.month,
    balance: history.balance && transformCurrencyAmountDto(history.balance),
  })),
  taxpayerRestricted: walletInvoices.taxpayer_restricted,
})
