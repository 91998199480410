'use client'

import { Cell } from '@vinted/web-ui'

import { useLocation } from '@marketplace-web/shared/browser'
import { useSession } from '@marketplace-web/shared/session'

import { getIsInCatalog } from 'pages/Catalog/utils/utils'
import { CatalogNavigationDto } from 'types/dtos'

import { SearchSubscribeButton } from '../SavedSearches'
import SearchBar from '../SearchBar'

type Props = {
  catalogTree: Array<CatalogNavigationDto>
  searchType?: ComponentProps<typeof SearchBar>['searchType']
}

const MobileHeaderSearchBar = ({ catalogTree, searchType }: Props) => {
  const { relativeUrl, searchParams } = useLocation()
  const userId = useSession().user?.id
  const isInCatalog = getIsInCatalog(relativeUrl)
  const disableSearchSaving = searchParams?.disable_search_saving

  const showSearchSubscribeButton = isInCatalog && userId && !disableSearchSaving

  return (
    <div className="l-search">
      <Cell styling={Cell.Styling.Tight}>
        <div className="u-ui-padding-vertical-regular">
          <div className="container">
            <div className="u-flexbox">
              <div className="u-fill-width">
                <SearchBar
                  catalogTree={catalogTree}
                  isInCatalog={isInCatalog}
                  searchType={searchType}
                />
              </div>

              {showSearchSubscribeButton && (
                <div className="u-phones-only u-ui-margin-left-medium">
                  <SearchSubscribeButton catalogTree={catalogTree} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Cell>
    </div>
  )
}

export default MobileHeaderSearchBar
