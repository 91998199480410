'use client'

import { Badge, Cell, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { CatalogAttribute, WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'
import { selectCatalogEvent } from '_libs/common/event-tracker/events'
import { CatalogNavigationModel } from 'types/models'

type Props = {
  selectedCategory: CatalogNavigationModel
  selectedCategoryId: string | number
}

const CatalogsPanel = ({ selectedCategory, selectedCategoryId }: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const parentCatalog = {
    ...selectedCategory,
    badge: null,
    code: WEB_CATALOG_ROOT_ALL_CODE,
    title: translate('header.main_navigation.catalogs.see_all'),
  }

  const catalogList: Array<CatalogNavigationModel> = [parentCatalog, ...selectedCategory.catalogs]

  const handleTitleClick =
    ({ id }: CatalogNavigationModel) =>
    () => {
      const trakingEvent = selectCatalogEvent({
        catalogIds: [id],
        attributeId: CatalogAttribute.Navigation,
      })

      track(trakingEvent)
    }

  const renderCatalogItem = (item: CatalogNavigationModel) => (
    <div key={item.id} className="catalog-dropdown__item">
      <Cell
        suffix={
          item.badge && <Badge theme={item.badge.theme || 'primary'} content={item.badge.title} />
        }
        type={Cell.Type.Navigating}
        styling={Cell.Styling.Narrow}
        body={
          <Text as="span" truncate>
            {item.title}
          </Text>
        }
        url={item.url}
        urlProps={{ title: item.title }}
        onClick={handleTitleClick(item)}
        testId={`catalog-item-${item.id}`}
        aria={{ 'aria-label': item.title }}
      />
    </div>
  )

  return (
    <div
      className={classNames('catalog-dropdown__category', {
        active: selectedCategory.id === selectedCategoryId,
      })}
    >
      {catalogList.map(renderCatalogItem)}
    </div>
  )
}

export default CatalogsPanel
