'use client'

import { ReactNode, useState, useEffect } from 'react'
import { Button, Text, Dialog, Spacer, Cell, Image, Navigation } from '@vinted/web-ui'
import classNames from 'classnames'
import { ArrowLeft24, X24 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import {
  itemUploadClickEvent,
  clickEvent,
  itemUploadViewScreenEvent,
} from '_libs/common/event-tracker/events'
import { PhotoTipModel } from 'types/models'
import { Screen } from 'constants/tracking/screens'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  isOpen: boolean
  isBodyHtml?: boolean
  photoTips: Array<PhotoTipModel>
  nextButtonText?: ReactNode
  backButtonText?: ReactNode
  handleClose?: () => void
  handleBack?: () => void
  uploadSessionId?: string
}

const PhotoTips = ({
  isOpen,
  photoTips,
  isBodyHtml,
  nextButtonText,
  backButtonText,
  handleClose,
  handleBack,
  uploadSessionId,
}: Props) => {
  const translate = useTranslate('item_upload.photo_tips')
  const { track } = useTracking()

  const [currentPageNumber, setCurrentPageNumber] = useState(0)

  useEffect(() => {
    if (!isOpen || !uploadSessionId) return

    track(itemUploadViewScreenEvent({ screen: Screen.PhotoTips, uploadSessionId }))
  }, [isOpen, track, uploadSessionId])

  function setCurrentPage(index: number) {
    return () => setCurrentPageNumber(index)
  }

  function renderIndicator() {
    if (!photoTips.length) return null

    return photoTips.map((photoTip, index) => {
      const actionClass = classNames('photo-tips__action', {
        'photo-tips__action--active': index === currentPageNumber,
      })

      return (
        <button
          type="button"
          key={photoTip.id}
          className="photo-tips__indicator"
          onClick={setCurrentPage(index)}
        >
          <span className={actionClass} />
        </button>
      )
    })
  }

  function handlePrevious() {
    setCurrentPageNumber(prevState => (prevState || photoTips.length) - 1)
  }

  function handleNext() {
    setCurrentPageNumber(prevState => (prevState === photoTips.length - 1 ? 0 : prevState + 1))
  }

  function renderPhotoTip() {
    const photoTip = photoTips[currentPageNumber]

    if (!photoTip) return null

    return (
      <>
        <Cell styling={Cell.Styling.Narrow}>
          <Text type={Text.Type.Heading} as="p">
            {photoTip.title}
          </Text>
        </Cell>
        <Cell styling={Cell.Styling.Narrow}>
          <Image src={photoTip.imageUrl} />
        </Cell>
        <Cell styling={Cell.Styling.Narrow}>
          <Text as="span" html={isBodyHtml}>
            {photoTip.body}
          </Text>
        </Cell>
      </>
    )
  }

  function renderTipsNavigation() {
    if (photoTips.length <= 1) return null

    return (
      <div className="u-flexbox">
        <Button
          testId="photo-tip-previous-button"
          onClick={handlePrevious}
          inline
          text={backButtonText || translate('previous_tip')}
        />
        <div className="photo-tips__indicator-container">{renderIndicator()}</div>
        <Button
          testId="photo-tip-next-button"
          onClick={handleNext}
          styling={Button.Styling.Filled}
          inline
          text={nextButtonText || translate('next_tip')}
        />
      </div>
    )
  }

  const handleModalClose = () => {
    if (!handleClose) return

    if (uploadSessionId) {
      track(
        itemUploadClickEvent({
          screen: Screen.PhotoTips,
          target: ClickableElement.PhotoTipsClose,
          uploadSessionId,
        }),
      )
    } else {
      track(
        clickEvent({
          screen: Screen.PhotoTips,
          target: ClickableElement.PhotoTipsClose,
        }),
      )
    }

    handleClose()
  }

  return (
    <Dialog testId="photo-tips-modal" show={isOpen && photoTips.length > 0}>
      <Cell>
        <Navigation
          left={
            handleBack && (
              <Button
                styling={Button.Styling.Flat}
                inline
                iconName={ArrowLeft24}
                onClick={handleBack}
                testId="back-button"
              />
            )
          }
          right={
            handleClose && (
              <Button
                styling={Button.Styling.Flat}
                inline
                iconName={X24}
                onClick={handleModalClose}
                testId="close-button"
              />
            )
          }
        />
        {renderPhotoTip()}
        <Spacer size={Spacer.Size.XLarge} />
        {renderTipsNavigation()}
      </Cell>
    </Dialog>
  )
}

export default PhotoTips
