'use client'

import { useLocation } from '@marketplace-web/shared/browser'

import { CatalogNavigationDto } from 'types/dtos'
import { getIsInCatalog } from 'pages/Catalog/utils/utils'

import SearchBar from '../SearchBar'

type Props = {
  catalogTree: Array<CatalogNavigationDto>
  searchType?: ComponentProps<typeof SearchBar>['searchType']
}

const DesktopHeaderSearchBar = ({ catalogTree, searchType }: Props) => {
  const { relativeUrl } = useLocation()

  return (
    <div className="u-desktops-only u-fill-width u-position-relative u-z-index-notification u-ui-margin-right-x3-large">
      <SearchBar
        catalogTree={catalogTree}
        isInCatalog={getIsInCatalog(relativeUrl)}
        searchType={searchType}
      />
    </div>
  )
}

export default DesktopHeaderSearchBar
