'use client'

import { useMemo } from 'react'
import { Divider } from '@vinted/web-ui'

import LanguageSelector from 'components/LanguageSelector'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { useSession } from '@marketplace-web/shared/session'

import { transformCatalogNavigationDtoList } from 'data/transformers/catalog'
import { transformUserDto } from '@marketplace-web/shared/user-data'
import { CatalogNavigationDto, LanguageDto } from 'types/dtos'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

import { AccountLinks, AccountLinksWithIcons } from '@marketplace-web/domain/user-menu'

import CategoriesSection from './CategoriesSection'
import LoginSection from './LoginSection'
import OtherSection from './OtherSection'

type Props = {
  catalogTree: Array<CatalogNavigationDto>
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
  initialLanguages: Array<LanguageDto>
}

const SideNavigation = ({
  catalogTree,
  impressumUrl,
  isBusinessAccountLinksVisible,
  initialLanguages,
}: Props) => {
  const { user } = useSession()
  const transformedUser = useMemo(() => (user ? transformUserDto(user) : null), [user])
  const transformedTree = useMemo(
    () => transformCatalogNavigationDtoList(catalogTree),
    [catalogTree],
  )

  const userMenuDropdownAbTest = useAbTest('user_menu_dropdown_reorder_web')
  useTrackAbTest(userMenuDropdownAbTest)

  const isUserMenuDropdOwnAbTestOn =
    userMenuDropdownAbTest && userMenuDropdownAbTest.variant !== 'off'

  return (
    <SeparatedList separator={<Divider />}>
      <LoginSection user={transformedUser} />
      <CategoriesSection categories={transformedTree} />
      {isUserMenuDropdOwnAbTestOn ? <AccountLinksWithIcons /> : <AccountLinks />}
      <LanguageSelector initialLanguages={initialLanguages} />
      <OtherSection
        impressumUrl={impressumUrl}
        isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
      />
    </SeparatedList>
  )
}

export default SideNavigation
