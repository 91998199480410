'use client'

import { useRef } from 'react'
import { Badge, Cell, Divider, Image, Text } from '@vinted/web-ui'

// TODO: remove this usage as it creates a circular dependency
import { UserMenuGroup } from '@marketplace-web/domain/user-menu'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { getStaffAccountAssertion } from 'data/api'
import { authenticateUser } from 'data/api/authentication/requests'
import { AuthenticateGrantType, AuthenticateProvider } from '@marketplace-web/shared/authentication'
import { getFingerprint } from '@marketplace-web/domain/audit'
import useRefUrl from 'hooks/useRefUrl'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { ManageableAccountDto } from 'types/dtos'
import { useBreakpoint, ShowAtBreakpoint } from '@marketplace-web/shared/breakpoints'

import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { useSession } from '@marketplace-web/shared/session'

const AccountSwitch = () => {
  const translate = useTranslate('header.user_menu.account.items.other_profile')
  const { user } = useSession()
  const refUrl = useRefUrl()
  const breakpoints = useBreakpoint()
  const clickedAccountIdRef = useRef<number | null>(null)

  const handleAccountClick = (userId: number) => async () => {
    clickedAccountIdRef.current = userId

    const response = await getStaffAccountAssertion(userId)
    const fingerprint = await getFingerprint()

    if ('errors' in response) {
      // TODO: handle error

      return
    }

    const authResponse = await authenticateUser({
      grantType: AuthenticateGrantType.Assertion,
      assertion: response.assertion,
      provider: AuthenticateProvider.Vinted,
      fingerprint,
    })

    if ('errors' in authResponse) {
      // TODO: handle error

      return
    }

    navigateToPage(refUrl)
  }

  useDataDomeCaptcha(() => {
    if (!clickedAccountIdRef.current) return

    handleAccountClick(clickedAccountIdRef.current)()
  })

  const renderProBadge = () => {
    if (user?.impersonating_business) return null

    return <Badge content={translate('badge')} />
  }

  const renderAccountLink = ({ icon, name, user_id: userId }: ManageableAccountDto) => (
    <Cell
      key={userId}
      prefix={<Image src={icon} size={Image.Size.Medium} styling={Image.Styling.Circle} />}
      subtitle={<Text as="span" text={name} />}
      suffix={renderProBadge()}
      styling={breakpoints.desktops ? Cell.Styling.Tight : Cell.Styling.Default}
      onClick={handleAccountClick(userId)}
      theme="transparent"
      clickable
    />
  )

  const renderDesktopAccount = (account: ManageableAccountDto) => (
    <UserMenuGroup.Action key={account.user_id}>{renderAccountLink(account)}</UserMenuGroup.Action>
  )

  if (!user?.manageable_accounts?.length) return null

  return (
    <div>
      <ShowAtBreakpoint breakpoint="desktops">
        <Divider />
        <Cell title={translate('title')} styling={Cell.Styling.Narrow} />
        {user.manageable_accounts.map(renderDesktopAccount)}
        <Divider />
      </ShowAtBreakpoint>
      <ShowAtBreakpoint breakpoint="portables">
        <Cell title={translate('title')} styling={Cell.Styling.Narrow} />
        {user.manageable_accounts.map(renderAccountLink)}
      </ShowAtBreakpoint>
    </div>
  )
}

export default AccountSwitch
