import { SearchSuggestionDto } from 'types/dtos'
import { SearchSuggestionModel } from 'types/models'

export const transformSearchSuggestion = (
  suggestion: SearchSuggestionDto,
): SearchSuggestionModel => {
  return suggestion
}

export const transformSearchSuggestions = (
  suggestions: Array<SearchSuggestionDto>,
): Array<SearchSuggestionModel> => suggestions.map(transformSearchSuggestion)
